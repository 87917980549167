<template>
  <div>
    <dialog-print :value="value" @input="$emit('input')" :makeFocus="false">
      <template v-slot:title v-if="title"> {{ title }}</template>
      <v-row class="ma-2">
        <v-col cols="12" sm="6" class="px-2">
          <a-form-view :value="data" :model="models.modelLeft" :config="{ dense: true }" />
          <a-form-view v-if="models.modelRight" :value="data" :model="models.modelRight" :config="{ dense: true }" />
        </v-col>
        <v-col cols="12" sm="12" class="px-2">
          <el-table v-if="!true" :data="data.data_table" stripe style="width: 100%; height: 100%"> </el-table>
          <a-table-f-data2
            style="height: 300px"
            v-else-if="false"
            ref="tableDetail"
            :dataTable="data.data_table"
            :model="models.modelDetail"
            :searchable="false"
            :defaults="{
              filters: { id: 1 },
              sort: { key: 'id', order: 'DESC' },
              paramName: 'documentGoodsIncomeDetail',
            }"
          />
          <v-data-table
            v-else
            dense
            :headers="headers"
            :items="data.data_table"
            item-key="id"
            hide-default-footer
            disable-pagination
            :items-per-page="-1"
            fixed-header
            class1="pa-21"
            style="overflow: hidden"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td v-for="(el, i) in headers" :key="i" class="px-1">
                  <component
                    v-if="false && el.type && componentExist(el.type)"
                    :is="getComponentName(el.type)"
                    :model="getComponentName(el.value)"
                    :value="item[el.value]"
                  />
                  <component v-else-if="el.type && componentExist(el.type)" :is="getComponentName(el.type)" :model="el" :value="item[el.value]" :values="item" />
                  <div v-else>
                    {{ el.type }}1
                    {{ item[el.value] }}
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </dialog-print>
  </div>
</template>

<script>
import { getAccess, genModel, components } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, genModel, components],
  props: {
    value: Boolean,
    data: { type: Object, default: () => {} },
    models: { type: Object, default: () => {} },
    title: String,
    config: { type: Object, default: () => {} },
    fields: String,
  },
  data() {
    return {};
  },
  computed: {
    headers() {
      let res = [];
      let f = this.fields ? this.fields.split(",") : [];
      console.log(this.models.modelDetail, f);
      this.models.modelDetail.forEach(m => {
        let r = Object.assign({}, m, { text: m.title, value: m.name });
        if (f.length && !f.includes(m.name)) return;
        if (!m.title) return;
        res.push(r);
      });
      return res;
    },
  },
  created() {},
  watch: {},
  methods: {
    clickRow(d) {
      this.$emit("select", d.row);
      this.$emit("input");
    },
  },
};
</script>
